import { addErrorHandler, getAppStatus } from "single-spa";

export default function ExceptionLogging() {
  const logging = (message) => {
    console.log(`[@lotte-colour/root-config] ${message}`);
  };

  addErrorHandler((err) => {
    logging(err);
    logging(
      `'${err.appOrParcelName}' has a status of '${getAppStatus(
        err.appOrParcelName
      )}'`
    );
  });
}
