import {
  registerApplication,
  start,
} from "single-spa";
import {
  constructApplications,
  constructRoutes,
  constructLayoutEngine,
} from "single-spa-layout";

import microfrontendLayout from "./microfrontend-layout.html";

import ExceptionLogging from "./exception-loging";
import { Authenticator } from "@lotte-colour/auth"

const routes = constructRoutes(microfrontendLayout);

const applications = constructApplications({
  routes,
  loadApp({ name }) {
    return System.import(name);
  },
});

const layoutEngine = constructLayoutEngine({ routes, applications });
applications.forEach(registerApplication);
layoutEngine.activate();

ExceptionLogging();

start();

window.addEventListener("single-spa:first-mount", () => {
  document.getElementById("spinner").style.display = "none";
});

window.addEventListener('single-spa:before-routing-event', async (evt: CustomEvent) => {
  const url = new URL(evt.detail.newUrl)
  let authUrl = null

  const authRoute:string[] = [
    "/manager",
    "/auth-callback"
  ]

  authRoute.forEach(route => {
    let match = url.pathname.toLowerCase().match(route.toLowerCase())
    if (match) {
      authUrl = route
    }
  });

  if (authUrl) {
    await Authenticator.authenticate("/manager")
  }
});